import React, { useRef, useEffect } from "react";
import {
  Box,
  Avatar,
  Typography,
  Paper,
  Container,
  Fade,
} from "@mui/material";
import { styled } from "@mui/system";
import RobotAvatar from "../../assets/images/Robot.svg";
import PersonIcon from '@mui/icons-material/Person';

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "50vh",
  display: "flex",
  flexDirection: "column",
  borderRadius: 16,
  overflow: "hidden",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
}));

const MessageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "20px",
  overflowY: "auto",
  backgroundColor: "#f5f5f5",
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#bdbdbd',
    borderRadius: '4px',
  },
}));

const MessageBubble = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'sent',
})(({ sent }) => ({
  display: "flex",
  alignItems: "flex-end",
  marginBottom: "16px",
  flexDirection: sent ? "row-reverse" : "row",
}));

const Message = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'sent',
})(({ sent }) => ({
  padding: "12px 16px",
  maxWidth: "70%",
  borderRadius: "16px",
  backgroundColor: sent ? "#10486D" : "#fff",
  color: sent ? "#fff" : "#000",
  marginLeft: sent ? "0" : "8px",
  marginRight: sent ? "8px" : "0",
  position: "relative",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  transition: "all 0.3s ease",
  '&:hover': {
    transform: "translateY(-1px)",
    boxShadow: "0 4px 8px rgba(0,0,0,0.15)"
  }
}));

export const ChatUI = ({ messages }) => {
  const messagesEndRef = useRef(null);

  // Desplazarse hacia el final cuando hay nuevos mensajes
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const formatTime = (timestamp) => 
    new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <StyledPaper>
        <MessageContainer>
          {messages.map((message) => (
            <Fade in={true} key={message.id} timeout={500}>
              <MessageBubble sent={message.sent}>
                <Avatar style={{backgroundColor: message.sent ? "" : "#10486D"}}>
                  {message.sent ? (
                    <PersonIcon />
                  ) : (
                    <Box
                      component="img"
                      src={RobotAvatar}
                      alt="Contact"
                      sx={{ width: 30, height: 30 }}
                    />
                  )}
                </Avatar>
                <Message sent={message.sent}>
                  <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                    {message.text}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: 0.5,
                      mt: 0.5,
                    }}
                  >
                    <Typography variant="caption" color={message.sent ? "rgba(255,255,255,0.7)" : "text.secondary"}>
                      {formatTime(message.created_at)}
                    </Typography>
                  </Box>
                </Message>
              </MessageBubble>
            </Fade>
          ))}
          <div ref={messagesEndRef} />
        </MessageContainer>
      </StyledPaper>
    </Container>
  );
};

