import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
// import {Login} from "./pages/Login/Login"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Layout} from "./components/Layout/Layout"
//Active
import {VirtualAssistant} from "./pages/VirtualAssistant/VirtualAssistant"

function App() {
  return (
    <HashRouter>
      <Routes>
        {/* <Route path="/"element = {<Login/>} />{" "} */}
        <Route path="/"element = { 
        <Layout title="Asistente Digital" >
          <VirtualAssistant/>
        </Layout>} />{" "}

        {/* <Route path="/voiceRecorder"element = { 
        <Layout title="Asistente Digital" >
          <VirtualAssistant/>
        </Layout>} />{" "}  */}

      </Routes>
      
      <ToastContainer />
    </HashRouter>
  );
}

export default App;
