import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import AssistantIcon from '@mui/icons-material/Assistant';
import Tooltip from "@mui/material/Tooltip";

import { Routes } from "../../routes";

const LinkBehavior = React.forwardRef((props, ref) => {
  return <NavLink ref={ref} to="/" {...props} />;
});

export const mainListItems = (
  <React.Fragment>
    <ListItemButton component={LinkBehavior} to={Routes.app_voicer_recorder}>
      <Tooltip title="Asistente Digital">
        <ListItemIcon>
          <AssistantIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Asistente Digital" />
    </ListItemButton>{" "}

  </React.Fragment>
);
