import React, { useState, useEffect } from "react";
import { RecordView } from '../../components/Recording/RecordingComponent.js';
import { ChatUI } from "../../components/Chat/Chat.js";
import { getUserMessages } from "../../api/request";
import { Box } from "@mui/material";

export function VirtualAssistant() {
    const [messages, setMessages] = useState([]);

    // Cargar los mensajes al montar el componente
    useEffect(() => {
        getUserMessages().then((previousMessages) => {
            setMessages(previousMessages);
        });
    }, []);

    const handleNewMessage = (newMessage) => {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" }, 
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                width: "100%",
                padding: "20px",
            }}
        >
            <Box sx={{ flex: { xs: "1", md: 3 }, width: "100%" }}>
                <RecordView onNewMessage={handleNewMessage} />
            </Box>
            <Box sx={{ flex: { xs: "1", md: 7 }, width: "100%" }}>
                <ChatUI messages={messages} /> {/* Pasamos los mensajes ya cargados */}
            </Box>
        </Box>
    );
}
