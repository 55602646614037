import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { mainListItems } from "../../components/SideBarItems/ListItems";
import { AppBar } from "../../utils/Material Styles/AppBar/AppBar";
import { Drawer } from "../../utils/Material Styles/Drawer/Drawer";
import { ProfileButtonMenu } from "../ButtonWithMenu/ProfileMenuButton";
import logo from "../../assets/images/logo-blanco-cenit.png";

const mdTheme = createTheme();

export function Layout(props) {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      {/* La box del sideBar */}
      <Box sx={{ display: "flex",maxHeight: "100vh" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} sx={{ backgroundColor: "#10486D" }}>
          <Toolbar sx={{ pr: "24px" }}> 
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo} alt="Logo Cenit" style={{ height: 40, width: "auto", marginRight: "20px" }} />
            <Typography component="h1" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              {props.title}
            </Typography>
            {/* Aqui va botón de cerrar sesión */}
            <ProfileButtonMenu />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", px: [1] }}>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {/* Acá estan los botones que se muestran en el menú lateral */}
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 4, mb: 4, p: 0, width: "100%" }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
              {props.children}
            </Paper>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
