import { useState, useEffect } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import { useReactMediaRecorder } from 'react-media-recorder';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { audioToText, processText } from '../../api/request';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import './Style.css';

export const RecordView = ({ onNewMessage }) => {
  const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({ audio: true });

  const [recordingTime, setRecordingTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseAudioUrl, setResponseAudioUrl] = useState(null);
  const [recordedBlobUrl, setRecordedBlobUrl] = useState(null);

  const handleStartRecording = () => {
    setRecordingTime(0);
    const id = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
    setIntervalId(id);
    startRecording();
    setResponseAudioUrl(null);
    setRecordedBlobUrl(null);
  };

  const handleStopRecording = () => {
    clearInterval(intervalId);
    stopRecording();
  };

  useEffect(() => {
    if (mediaBlobUrl) {
      setRecordedBlobUrl(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  const formatTime = (timeInSeconds) => {
    const minutes = String(Math.floor(timeInSeconds / 60)).padStart(2, '0');
    const seconds = String(timeInSeconds % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const sendAudioFile = async () => {
    if (recordedBlobUrl) {
      setIsLoading(true);
  
      try {
        // Convertir la grabación a un archivo para enviarlo
        const response = await fetch(recordedBlobUrl);
        const audioBlob = await response.blob();
        const audioFile = new File([audioBlob], 'audio.mp3', { type: 'audio/mpeg' });
        const formData = new FormData();
        formData.append('audio', audioFile);
  
        // Primera solicitud: transcribir audio
        const apiResponse = await audioToText(formData);
  
        if (apiResponse.status === 200) {
          const responseText = apiResponse.data;
          onNewMessage(responseText);
          toast.success("Audio enviado con éxito!");
  
          // Preparar datos para la segunda solicitud a processText
          const processFormData = new FormData();
          processFormData.append('text', responseText.text);
  
          // Segunda solicitud: procesar texto para obtener respuesta en audio y JSON
          let ia_response = await processText(processFormData);
  
          if (ia_response.status === 200) {
            const { ia_response: iaJsonResponse, audio_base64 } = ia_response.data;

            const assistantMessage = {
              _id: iaJsonResponse._id,
              created_at: iaJsonResponse.created_at,
              sent: false,
              text: iaJsonResponse.ia_response.ia_response,
              user_id: iaJsonResponse.user_id,
            };
  
            // Decodificar audio base64 a Blob y crear URL
            const binaryString = atob(audio_base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const audioBlob = new Blob([bytes], { type: 'audio/mpeg' });
            const responseAudioUrl = URL.createObjectURL(audioBlob);
            setResponseAudioUrl(responseAudioUrl);
  
            // Pasar el objeto assistantMessage al componente padre para mostrar en el chat
            onNewMessage(assistantMessage);
          } else {
            toast.error("Hubo un problema al procesar el texto.");
          }
        } else {
          toast.error("Hubo un problema al enviar el audio.");
        }
      } catch (error) {
        toast.error("Error al conectar con el servidor.");
        console.error('Error al obtener o enviar el audio:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log("No recordedBlobUrl available.");
    }
  };
  
  const getTitle = () => {
    if (responseAudioUrl) {
      return (
        <>
          Respuesta recibida. <br /> ¿Tienes otra pregunta?
        </>
      );
    }
    if (status === 'recording') {
      return <>Escuchando tu pregunta...</>;
    }
    if (status !== 'recording' && recordedBlobUrl) {
      return <>Listo para enviar. ¡Adelante!</>;
    }
    return <>¿En qué puedo ayudarte?</>;
  };

  return (
    <div className="container">
      <h2 className="title">Asistente Digital Cenit</h2>
      <div>
        <div className="recording-container">
          <p className="instructions">{getTitle()}</p>
          <div className="timer">{formatTime(recordingTime)}</div>
          <button
            onClick={status === 'recording' ? handleStopRecording : handleStartRecording}
            className="record-button"
          >
            <span aria-label="microphone">
              <MicIcon sx={{ fontSize: { xs: 24, sm: 36 } }} />
            </span>
          </button>
        </div>

        <div className="send-audio-container">
          {responseAudioUrl ? (
            <div className="response-text">
              <h3>Respuesta del Asistente:</h3>
              <audio src={responseAudioUrl} controls className="audio-player" />
            </div>
          ) : (
            status !== 'recording' && recordedBlobUrl && (
              <div className="send-audio">
                <audio src={recordedBlobUrl} controls className="audio-player" />
                <Button
                  onClick={sendAudioFile}
                  className="send-button"
                  disabled={isLoading}
                  variant="contained"
                  style={{backgroundColor:"#10486D", marginTop:"20px"}}
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                  Consultar
                </Button>
              </div>
            )
          )}
        </div>
      </div>

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default RecordView;
