import axios from 'axios';
import {Routes} from '../routes';

const userId = '672b7be5676f149463c299a8'

//return status 200 if the data is correct
export async function login(data) {
  try {
      const response = await axios.post(`${Routes.api_url_base + Routes.api_login}`,data)
      return response.data;

    } catch (e) {
      return e.response
  }
}


export async function audioToText(audioFormData) {
  try {

    audioFormData.append('userId', userId);

    const response = await axios.post(
      `${Routes.api_url_base + Routes.api_audio}`, 
      audioFormData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }
    return e.response;
  }
}

export async function processText(formData) {
  try {
    formData.append('userId', userId);

    const response = await axios.post(
      `${Routes.api_url_base + Routes.api_process_text}`, 
      formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'json',
      }
    );
    return response;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }
    return e.response;
  }
}

export async function getUserMessages() {
  try {
    const response = await axios.get(`${Routes.api_url_base}/openAI/getUserMessages/${userId}`);
    return response.data;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }
    return e.response.data;
  }
}