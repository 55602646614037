export const Routes = {


  app_url_base: 'http://localhost:3000',
  app_voicer_recorder:'/',
  // app_voicer_recorder:'/voiceRecorder',

  //-------------------API-------------------

  api_url_base: 'https://api.industrial-assistant.ai',

  api_login: '/api/login/',

  api_audio:'/openAI/uploadAudio',
  api_process_text:'/openAI/processText',

};
